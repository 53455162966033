import React from "react"
import Section from "../Components/Section"
import { links, copy, releaseDate } from "../data"
import logoPath from "../assets/logo/logo.png"
import Icons from "../Components/Icons"
import FadeIn from "../Components/FadeIn"
import useIsMobile from "../hooks/useIsMobile"

export default function MainSection(){
  const isMobile = useIsMobile()
  const isReleased = React.useMemo(() => {
    const currentDate = new Date()
    return currentDate >= releaseDate
  }, [])
  const currentHeading = isReleased ? copy.mainSection.heading : copy.mainSection.headingPreRelease
  const currentHeadingSizeClass = isMobile ? "display-6" : "display-4"

  const logo = (
    <img 
      className="col-7 col-lg-6"
      src={logoPath}
      alt="Dialith Logo"
      style={{
        filter: 'invert(1)',
        marginLeft: '4vw',
      }}
    />
  )

  const buttonClasses = isMobile ? "fs-5" : "btn-lg fs-3"

  return (
    <Section id="home" className={isMobile ? "pb-5" : ""}>
      <FadeIn>
        {logo}
        <h1 className={`mb-3 mb-md-5 ${currentHeadingSizeClass}`}>
          {currentHeading}
        </h1>
        <a href={links.bandcamp} target="_blank" rel="noopener noreferrer">
          <button
            className={`btn btn-outline-light mb-0 mb-md-3 ${buttonClasses}`}
          >
            {isReleased ? "Bandcamp" : "Preorder Now"}
          </button>
        </a>
        <Icons />
      </FadeIn>
    </Section>
  );
}
