import React from "react";
import Icon from "../Icon";

export default function TiktokIcon() {
  return (
    <Icon
      path={
        <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
      }
    />
  );
}
