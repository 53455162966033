import React from "react"
import Section from "../Components/Section"
import { copy, links, newReleaseName, bandcampPlayerSrcDesktop, bandcampPlayerSrcMobile } from "../data"
import FadeIn from "../Components/FadeIn"
import useIsTablet from "../hooks/useIsTablet"
import useIsMobile from "../hooks/useIsMobile"

export default function NewReleaseSection(){
  const { quotesHeading, quotes, playerHeading } = copy.newReleaseSection
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()

  const bandcampPlayerStyle = isTablet
    ? // Small
      {
        width: 275,
        height: 275,
      }
    : // Large
      {
        width: 550,
        height: 788,
      };

  const bandcampPlayerSrc = isTablet
    ? bandcampPlayerSrcMobile
    : bandcampPlayerSrcDesktop

  const bandcampPlayer = (
    <iframe
      style={bandcampPlayerStyle}
      className="custom-shadow"
      src={bandcampPlayerSrc}
      title="Bandcamp Player"
      seamless
    >
      <a href={links.newReleaseBandcamp}>{newReleaseName} by Dialith</a>
    </iframe>
  )

  return (
    <Section id="new-releases" className={isMobile ? "pb-5" : ""}>
      <div className="row justify-content-around" style={{ marginTop: "10vh" }}>
        <div className="col-md-auto mb-5">
          <FadeIn>
            <h1 className="display-5 mb-4 mb-md-5">{playerHeading}</h1>

            {bandcampPlayer}
          </FadeIn>
        </div>

        {!isMobile &&
          <div id="praise-column" className="col-md-4">
            <FadeIn>
              <h1 className="display-5 mb-5 mb-md-0">{quotesHeading}</h1>

              <div className="d-flex flex-column justify-content-around">
                {quotes.map((quote) => (
                  <Quote
                    quote={quote.quote}
                    name={quote.name}
                    link={quote.link}
                  />
                ))}
              </div>
            </FadeIn>
          </div>
        }
      </div>
    </Section>
  );
}

function Quote({quote, name, link}){
  return (
    <figure className="col-md-12 py-md-5" style={{height: "30%"}}>
      <blockquote className="blockquote fs-2 fst-italic">
        <p>"{quote}"</p>
      </blockquote>

      <figcaption className="blockquote-footer fs-4">
        <a href={link}>{name}</a>
      </figcaption>
    </figure>
  )
}