import React from "react"
import { links } from "../data"

import AppleMusicIcon from "./icons/AppleMusicIcon"
import FacebookIcon from "./icons/FacebookIcon"
import InstagramIcon from "./icons/InstagramIcon"
import SpotifyIcon from "./icons/SpotifyIcon"
import YoutubeIcon from "./icons/YoutubeIcon"
import TiktokIcon from "./icons/TiktokIcon"

export default function Icons(props){

  const iconElements = React.useMemo(() => {
    const icons = [
      {
        icon: SpotifyIcon,
        link: links.spotify,
      },
      {
        icon: AppleMusicIcon,
        link: links.appleMusic,
      },
      {
        icon: YoutubeIcon,
        link: links.youtube,
      },
      {
        icon: TiktokIcon,
        link: links.tiktok,
      },
      {
        icon: InstagramIcon,
        link: links.instagram,
      },
      {
        icon: FacebookIcon,
        link: links.facebook,
      },
    ]

    return icons.map(({ icon, link }) => (
      <a
        href={link}
        key={icon.toString()}
        className="mx-4 mt-4"
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon()}
      </a>
    ))
  }, [])

  return (
    <div className={`d-flex justify-content-center flex-wrap ${props.className}`}>
      {iconElements}
    </div>
  )
}