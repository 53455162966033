import React from "react"
import Section from "../Components/Section"
import { links, copy } from "../data"
import useIsMobile from "../hooks/useIsMobile"
import FadeIn from "../Components/FadeIn"

export default function YoutubeVideoSection() {
  const { heading, subheading } = copy.youtubeSection
  const isMobile = useIsMobile()
  const playerWidth = isMobile ? 350 : 700

  const youtubePlayer = (
    <iframe
      src={links.newReleaseYoutube}
      className="custom-shadow"
      width={playerWidth}
      height={(playerWidth * 9) / 16} // 16:9 aspect ratio
      title="YouTube Video Player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  )

  return (
    <Section id="youtube-video">
      <div className="row flex-column justify-content-evenly h-100">
        <FadeIn>
          <div className="col mb-5">
            <div className="d-flex flex-column justify-content-center">
              <h1 className="display-3 mb-5">{heading}</h1>
              <h3 className="display-5">{subheading}</h3>
            </div>
          </div>
  
          <div className="mt-0 mt-md-5">{youtubePlayer}</div>
        </FadeIn>
      </div>
    </Section>
  );
}
