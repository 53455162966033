import React from "react"
import Section from "../Components/Section"
import { bandMembers } from "../data"
import useIsMobile from "../hooks/useIsMobile"
import useIsTablet from "../hooks/useIsTablet"
import FadeIn from "../Components/FadeIn"

export default function BandMembersSection(){
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const bandMembersCarousel = React.useMemo(() => (
    <div id="band-member-carousel" className="carousel slide custom-shadow" style={{margin: "0 25px"}}>
      <div className="carousel-indicators">
        {bandMembers.map((_member, index) => (
          <button
            type="button"
            data-bs-target="#band-member-carousel"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current="true"
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>

      <div className="carousel-inner">
        {bandMembers.map((member, index) => (
          <div className={`carousel-item ${index === 0 ? "active" : ""}`}>
            <img
              src={member.photo}  
              alt={member.name} 
              className="d-block w-100" 
            />
            <div className="carousel-caption d-block" style={{backgroundColor: "rgba(0, 0, 0, .6", width: "100%", left: 0}}>
              <h5>{member.name}</h5>
              <h6><i>{member.instrument}</i></h6>
            </div>
          </div>
        ))}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#band-member-carousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#band-member-carousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>), [])

  const bandMemberTileWidthClass = isTablet ? "w-50" : "w-100"

  const bandMembersTiles = React.useMemo(
    () => (
      <div className="row row-cols-2 row-cols-xl-4 px-5">
        <FadeIn>
          {bandMembers.map((member) => (
            <div className="band-member text-center">
              <img
                src={member.photo}
                alt={member.name}
                className={`custom-shadow ${bandMemberTileWidthClass}`}
              />
              <h1 className="my-3">{member.name}</h1>
              <h3>
                <i>{member.instrument}</i>
              </h3>
            </div>
          ))}
        </FadeIn>
      </div>
    ), [bandMemberTileWidthClass]
  )

  return (
    <>
      <Section id="band-members">
        <FadeIn>
          <h1 className="display-3 mb-3 mb-md-5">Dialith is:</h1>
    
          { isMobile
            ? bandMembersCarousel
            : bandMembersTiles
          }
        </FadeIn>
      </Section>
    </>
  )
}
