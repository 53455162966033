import React from "react"
import { ICON_SIZE, ICON_SIZE_SMALL } from "../constants"
import useIsMobile from "../hooks/useIsMobile"

export default function Icon(props){
  const [hovered, setHovered] = React.useState()
  const isMobile = useIsMobile()
  let currentIconSize = isMobile ? ICON_SIZE_SMALL : ICON_SIZE

  if (props.sizePercent) {
    currentIconSize = Math.ceil(currentIconSize * (props.sizePercent * 0.01));
  }

  const style = {
    transition: "all 0.1s",
    transform: hovered ? "scale(1.25)" : "",
    transformOrigin: "50% 50%",
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={currentIconSize}
      height={currentIconSize}
      fill="currentColor"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={style}
      viewBox="0 0 16 16"
    >
      {props.path}
    </svg>
  )
}
