import React from "react"
import { backgroundImages } from "../data"
import useScrollY from "../hooks/useScrollY"

export default function BackgroundContainer(){
  const scrollY = useScrollY()

  return (
    <div id="background-container">
      {backgroundImages.map((image, index) =>
        <BackgroundImage
          key={index}
          image={image} 
          index={index}
          scrollY={scrollY}
        />
      )}
    </div>
  )
}

function BackgroundImage({image, index, scrollY}){
  const [opacity, setOpacity] = React.useState(index === 0 ? 1 : 0)

  const backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})`

  React.useEffect(() => {
    if (index === 0) {
      return
    }

    const start = window.innerHeight * (index - 1)

    if (scrollY < start){
      setOpacity(0)
      return
    }

    const end = window.innerHeight * index
    const newOpacity = (scrollY - start) / (end - start)

    setOpacity(newOpacity);
  }, [scrollY, index])

  return (
    <div
      className="background-image"
      style={{
        backgroundImage: backgroundImage,
        opacity: opacity,
        position: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    ></div>
  );
}
