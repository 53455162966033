import React from "react"
import useIsMobile from "../hooks/useIsMobile";

export default function Section(props){
  const isMobile = useIsMobile()

  const contentStyle = {
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "1500px",
    maxHeight: isMobile ? "80vh" : "",
  }

  return (
    <section
      id={props.id}
      style={{
        position: "relative",
        height: "100vh",
        scrollSnapAlign: "start",
      }}
    >
      <div className={`section-content container-fluid ${props.className}`} style={contentStyle}>
        {props.children}
      </div>
    </section>
  );
}