import React from "react";

import Navbar from "./Components/Navbar"
import MainSection from "./Sections/MainSection"
import NewReleaseSection from "./Sections/NewReleaseSection"
import BackgroundContainer from "./Components/BackgroundContainer"
import YoutubeVideoSection from "./Sections/YoutubeVideoSection"
import BandMembersSection from "./Sections/BandMembersSection"
import Footer from "./Components/Footer"

export default function App() {
  // const acknowledgement = (
  //   <sub>
  //     Ivy Leaf SVG by Philipp Lehmann from
  //     <a
  //       href="https://thenounproject.com/browse/icons/term/ivy-leaf/"
  //       target="_blank"
  //       rel="noreferrer noopener"
  //       title="ivy leaf Icons"
  //     >
  //       Noun Project
  //     </a>
  //     (CC BY 3.0)
  //   </sub>
  // )

  // Scroll snap classes located in src/App.css

  return (
    <>
      <BackgroundContainer />
      <Navbar />
      <MainSection />
      <NewReleaseSection />
      <YoutubeVideoSection />
      <BandMembersSection />
      <Footer />
    </>
  )
}
