import React from "react"
import { links } from "../data"

export default function Footer(){
  const currentYear = React.useMemo(() => new Date().getFullYear(), [])
  const copyrightSymbol = (
    <span className="pe-1 pb-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-c-circle"
        viewBox="0 0 16 16"
      >
        <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512" />
      </svg>
    </span>
  )
  
  const verticalDivider = (
    <span className="px-3">|</span>
  )

  const style = {
    width: "100vw",
    position: "fixed",
    bottom: 0,
  }

  return (
    <footer id="footer" className="container-fluid bg-translucent" style={style}>
      <span className="d-flex justify-content-center align-items-center py-2" style={{opacity: "0.55"}}>
        {copyrightSymbol} {currentYear} Dialith {verticalDivider} <EmailLink />
      </span>
    </footer>
  )
}

function EmailLink(){
  const [hovered, setHovered] = React.useState(false)
  const emailSymbol = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      className="bi bi-envelope"
      viewBox="0 0 16 16"
    >
      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
    </svg>
  )

  return (
    <a
      href={links.email}
      style={{
        opacity: hovered ? "1" : "0.55"
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {emailSymbol}
    </a>
  );
}