export const links = {
  appleMusic: "https://music.apple.com/us/artist/dialith/1472665827",
  bandcamp: "https://dialith.bandcamp.com",
  facebook: "https://www.facebook.com/dialithband",
  instagram: "https://www.instagram.com/dialithband/",
  spotify: "https://open.spotify.com/artist/4KzQket8KMlzdmmxzgwBfp",
  tiktok: "https://www.tiktok.com/@dialithband",
  youtube: "https://www.youtube.com/DialithBand",
  email: "mailto:dialithband@gmail.com",

  // For embedded media players
  newReleaseBandcamp: "https://dialith.bandcamp.com/album/atrophy",
  newReleaseYoutube: "https://www.youtube.com/embed/0mu9ZDiDIFE?si=y2HSf6QE2aakjyX2",
}

/*
  Each image will correspond to a background image for each fullscreen <Section /> component
  There should be an equal number of images in this array to the number of sections
    - If there are too many, the images at the front of the array will not be seen
*/ 
export const backgroundImages = [
  require("./assets/alter/alterCoverCropped.jpg"),
  require("./assets/alter/alterCoverCroppedBlurred.jpg"), // Use 16px blur
  require("./assets/alter/alterBranch.jpg"),
  require("./assets/misc/stoneWall.jpg"),
]

export const newReleaseName = "Alter"
export const prevReleaseName = "Atrophy"
export const releaseDate = new Date("2024/04/05 EST") // Year-Month-Day Timezone
export const releaseDateFormatted = releaseDate.toLocaleDateString("en-US", {dateStyle: "medium"})

export const copy = {
  mainSection: {
    heading: <><i>{newReleaseName}</i> Available Now</>,
    headingPreRelease: <><i>{newReleaseName}</i> coming {releaseDateFormatted}</>
  },
  newReleaseSection: {
    quotesHeading: <>Praise for <i>{prevReleaseName}</i></>,
    quotes: [
      {
        quote: "The hooks are strong, the songwriting is compelling and dynamic, the musicianship is superb, and the sound is excellent. Make sure you do not miss this EP.",
        name: "LotsofMuzik",
        link: "https://lotsofmuzik.com/dialith-alter-ep-review-by-curtis-j-dupree/",
      },
      // {
      //   quote: "Quote here",
      //   name: "Name",
      //   link: "https://dialith.bandcamp.com/album/atrophy",
      // },
    ],
    playerHeading: "Listen now:",
  },
  youtubeSection: {
    heading: "Latest Music Video",
    subheading: <>A Middle Eastern inspired song with an epic drum solo</>,
  },
}

export const bandMembers = [
  {
    name: "Krista Sion",
    instrument: "Vocals",
    photo: require("./assets/bandMembers/krista.jpg"),
  },
  {
    name: "Alasdair Wallace Mackie",
    instrument: "Guitar, Orchestrations",
    photo: require("./assets/bandMembers/alasdair.jpg"),
  },
  {
    name: "Cullen Mitchell",
    instrument: "Drums",
    photo: require("./assets/bandMembers/cullen.jpg"),
  },
  {
    name: "Mark Grey",
    instrument: "Bass",
    photo: require("./assets/bandMembers/mark.jpg"),
  },
]

// Get these values by clicking 'Share/Embed' on the Bandcamp page
const bandcampPlayerAlbumId = 3971762677
const bandcampPlayerLinkColor = "2ebd35" // Hex value
const bandcampPlayerBgColor = "ffffff" // Hex value

// ! Do not edit these constants, only the Bandcamp variables above
export const bandcampPlayerSrcDesktop = `https://bandcamp.com/EmbeddedPlayer/album=${bandcampPlayerAlbumId}/size=large/bgcol=${bandcampPlayerBgColor}/linkcol=${bandcampPlayerLinkColor}/transparent=true/`
export const bandcampPlayerSrcMobile = `https://bandcamp.com/EmbeddedPlayer/album=${bandcampPlayerAlbumId}/size=large/bgcol=${bandcampPlayerBgColor}/linkcol=${bandcampPlayerLinkColor}minimal=true/transparent=true/`