import * as React from "react";

const useIsTablet = (mobileScreenSize = 1200) => {
  if (typeof window.matchMedia !== "function") {
    throw Error("matchMedia not supported by browser!");
  }
  const [isTablet, setIsTablet] = React.useState(
    window.matchMedia(`(max-width: ${mobileScreenSize}px)`).matches
  );

  const checkIsTablet = React.useCallback((event) => {
    setIsTablet(event.matches);
  }, []);

  React.useEffect(() => {
    const mediaListener = window.matchMedia(
      `(max-width: ${mobileScreenSize}px)`
    );
    // try catch used to fallback for browser compatibility
    try {
      mediaListener.addEventListener("change", checkIsTablet);
    } catch {
      mediaListener.addListener(checkIsTablet);
    }

    return () => {
      try {
        mediaListener.removeEventListener("change", checkIsTablet);
      } catch {
        mediaListener.removeListener(checkIsTablet);
      }
    };
  }, [mobileScreenSize, checkIsTablet]);

  return isTablet;
};

export default useIsTablet;
