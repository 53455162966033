import React from "react"
import { Fade } from "react-awesome-reveal"

export default function FadeIn(props){
  return (
    <Fade 
      cascade
      damping={0.1}
      fraction={0.9}
      triggerOnce
      {...props}
    >
      {props.children}
    </Fade>
  );
}