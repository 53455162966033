import React from "react"
import useIsMobile from "../hooks/useIsMobile"
import useScrollY from "../hooks/useScrollY"
import { links } from "../data"
import { ReactComponent as BandLogo }from "../assets/logo/logo.svg"

export default function Navbar(){
  const isMobile = useIsMobile()
  const centerNavClass = !isMobile ? "d-flex justify-content-center" : ""

  return (
    <nav className="navbar fixed-top navbar-expand-lg py-1 bg-translucent" data-bs-theme="dark">
      <div className="container-fluid">
        {isMobile && <NavbarLogo />}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMain"
          aria-controls="navbarMain"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          id="navbarMain"
          className={`collapse navbar-collapse ${centerNavClass}`}
        >
          <ul className="navbar-nav d-table justify-content-center">
            <NavItem href="#new-releases" name="Music" />
            <NavItem href="#youtube-video" name="Video" />
            {!isMobile && <NavbarLogo />}
            <NavItem href="#band-members" name="Band" />
            <NavItem href={links.bandcamp} name="Merch" target="_blank" rel="noreferrer noopener" />
          </ul>
        </div>
      </div>
    </nav>
  )
}

function NavbarLogo() {
  const [hovered, setHovered] = React.useState(false)
  const scrollY = useScrollY()
  const [hidden, setHidden] = React.useState(true)
  const offset = 64 // Roughly the height of navbar. Use to have logo appear when scrolled at a more natural spot

  const logo = (
    <BandLogo
      style={{
        maxHeight: "10vh",
        maxWidth: "150px",
        opacity: hovered ? "1" : "0.55",
        transition: `opacity 0.1s, width 0.35s`,
        transformOrigin: "50% 50%",
        width: hidden ? "0%" : "100%",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  );

  const style = {
    // transform: hidden ? "scale(0)": "",
    transition: `width 0.1s`,
    width: hidden ? "0px" : "",
  }

  React.useEffect(() => {
    setHidden(!(scrollY >= window.innerHeight - offset))
  }, [scrollY])

  return (
    <a 
      className="navbar-brand d-flex justify-content-center m-0" 
      href="#home"
      style={style}
    >
      {logo}
    </a>
  )
}

function NavItem(props) {
  return (
    <a
      href={props.href}
      className="nav-link d-md-table-cell align-middle px-md-5 pt-md-3 fs-3"
      style={{
        textTransform: "uppercase",
      }}
      target={props.target ?? ""}
      rel={props.rel ?? ""}
    >
      {props.name}
    </a>
  );
}
