import React from "react"

const useScrollY = () => {
  const [scrollY, setScrollY] = React.useState(window.scrollY)

  const handleScroll = () => {
    setScrollY(window.scrollY)
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {window.removeEventListener("scroll", handleScroll)}
  })

  return scrollY
}

export default useScrollY
